<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $getByLang(body.name) }}</h1>
        </div>
      </div>
    </section>
    <!--SECTION START-->
    <section style="background-color: white;padding-top: 20px;">
      <div class="container ">
        <div class="mb-4">
          <img
            style="max-height: 400px;width: 100%;"
            :src="baseUploadURL2 + body.image"
            alt=""
          />
        </div>
        <div class="mb-4 mt-4">
          <div>
            <h3>{{ $t("Offers Details") }}:</h3>
            <!-- <ul>
              <li v-for="itm of prices" :key="itm.id">
                {{ itm.minutes }} {{$t('minutes')}} = {{ itm.price }}€
              </li>
            </ul> -->
            <p>
              {{ $getByLang(body.body) }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      style="background-color: white;padding-top: 150px;
padding-bottom: 150px;"
    >
      <div class="container ">
        <!-- <form method="post">
          <div class="mb-3">
            <label for="name" class="form-label"> {{ $t('Name') }}</label>
            <input
              type="text"
              class="form-control"
              id="fname"
              v-model="xbody.name"
            />
          </div>

          <div class="mb-3">
            <label for="phone" class="form-label">{{ $t('Phone') }}</label>

            <vue-tel-input v-model="xbody.phone"></vue-tel-input>
          </div>

          <b-button
            block
            variant="danger"
            :disabled="!this.xbody.name && !this.xbody.phone"
            @click="add"
            style="background-color: #f26838;color: white;"
            >{{ $t('Confirm') }}</b-button
          >
        </form> -->
        <a
          block
          variant="danger"
          href="https://salonkee.lu/salon/egypta-massage-bereldange?lang=fr"
          style="background-color: #f26838;color: white;"
          >{{ $t("Confirm") }}</a
        >
      </div>
    </section>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="(v) => (loading = v)"
      />
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey =
      "pk_live_51HQAAeKJnzPR2F8FX59aaFrWGwpNLgd48uKrvRLB6F5FUYong46ShbrDaGbm2Qt1JrUazDb2XYEVLczJdiNOM0hF000flxqUoI";

    return {
      body: {
        name: null,
        body: null,
        price: 0,
        id: null,
        api_id: null,
      },
      xbody: {
        name: null,
        phone: null,
      },
      prices: [],
      baseUploadURL2: this.$baseUploadURL2,
      lineItems: [
        {
          price: null, // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: null,
      cancelURL: null,
      orderId: null,
    };
  },

  created() {
    if (this.$route.query.id) {
      // alert(this.$route.query.id);
      if (this.$route.query.success) {
        // alert(1);
        // orderId;

        this.$http2
          .post(`packagesuser/update/${this.orderId}`, {
            bayDone: 1,
          })
          .then((res) => {
            this.$swal.fire({
              icon: "success",
              title: "Success",
              text: this.$t("Success Done"),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        // alert(0);
      }
    }
    const slug = this.$route.params.slug;
    if (slug) {
      this.$http2.get(`packages/getById/${slug}`).then(
        (res) => {
          if (res.data) {
            this.body = res.data;

            this.lineItems[0].price = this.body.api_id;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },

    add() {
      if (this.xbody.name && this.xbody.phone) {
        this.$eventHub.$emit("showSpinner", true);
        this.$http2
          .post(`packagesuser/add`, {
            ...this.xbody,
            balance: this.body.price,
            packages_id: this.body.id,
          })
          .then(
            (res) => {
              this.successURL =
                document.baseURI +
                this.$getLink() +
                `?id=${res.data.id}&success=1`;
              this.cancelURL =
                document.baseURI +
                this.$getLink() +
                `?id=${res.data.id}&success=0`;
              this.orderId = res.data.id;
              this.$eventHub.$emit("showSpinner", false);
              this.$swal.fire({
                icon: "success",
                title: "Success",
                text: this.$t("Success Done"),
                showConfirmButton: false,
                timer: 1500,
              });
              this.successURL;
              setTimeout(() => {
                // if (this.body.baytype) {
                // this.submit();
                // } else {
                this.$router.push("/");
                // }
              }, 1500);
            },
            (err) => {
              this.$eventHub.$emit("showSpinner", false);
              this.$swal.fire({
                icon: "error",
                title: "Error",
                text: this.$t("Something has gone wrong"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          );
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: this.$t("Fields must be filled out"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>

<style></style>
